<template>
  <Usuarios></Usuarios>
</template>

<script>
import { defineComponent } from 'vue'

import Usuarios from '../components/Usuarios.vue'

export default defineComponent({
  name: 'UsuariosView',

  components: {
    Usuarios
  }
})
</script>
