<template>
  <v-app >
    <br />
    <v-card-title class="text-h4 font-weight-medium" style="margin-left: 20px">
      Usuarios
    </v-card-title>
    <div class="bottonusuario">
            <v-dialog v-model="dialog" max-width="600px" class="pa-4 text-center" persistent>
              <template v-slot:activator="{ props: activatorProps }">
                <v-btn size="large" color="#06a454" dark v-bind="activatorProps">
                  Nuevo Usuario
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text >
                  <v-container>
                    <div class="img-container">
                    <v-img src="https://cdn4.iconfinder.com/data/icons/music-ui-solid-24px/24/user_account_profile-2-256.png" />
                    </div>
                    <br />
                        <v-text-field
                          v-model="editedItem.nomUsuario"
                          label="Nombre"
                          :rules="[rules.required]"
                        ></v-text-field>
                        <v-row>
                        <v-col>
                        <v-text-field
                          v-model="editedItem.apPatUsuario"
                          label="Apellido Paterno"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="editedItem.apMatUsuario"
                          label="Apellido Materno"
                        ></v-text-field>
                      </v-col>
                        </v-row>
                        <v-row>
                        <v-col>
                        <v-text-field
                          v-model="editedItem.usuario"
                          label="Usuario"
                          :rules="[rules.required, rules.userStartsWithAt,  userExists]"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-autocomplete v-model="editedItem.idTipoUsuario" :items="tipo" item-title="nomTipoUsuario" item-value="idTipoUsuario"
                        label="Seleccione tipo usuario" :rules="[rules.required]">
                        </v-autocomplete>
                      </v-col>
                        </v-row>
                        <v-text-field
                        v-if="editedIndex === -1"
                        v-model="editedItem.contraseña"
                        :rules="[rules.required, rules.password]"
                        label="Contraseña"
                        placeholder="Ingrese su contraseña"
                        :type="show1 ? 'text' : 'password'"
                        hint="Ingrese 8 caracteres"
                        name="input-10-1"
                        counter
                        @click:append-inner="show1 = !show1"
                        prepend-inner-icon="mdi-lock"
                        :append-inner-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                        clearable
                      ></v-text-field>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="gray-darken-1" variant="text" @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn color="#06a454" variant="text" @click="save">
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="showErrorDialog" max-width="500" persistent>
              <v-card>
                <v-card-title class="text-center">
                  <v-icon color="red" large class="mr-2">mdi-alert-circle</v-icon>
                  Atención</v-card-title>
                  <v-card-text class="text-center">
                    Por favor, Complete los campos correctamente antes de continuar.
                  </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#06a454" text @click="showErrorDialog = false">Aceptar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px" persistent>
              <v-card>
                <v-card-title class="text-center"
                  >¿Seguro quieres eliminar este usuario?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="gray-darken-1"
                    variant="text"
                    @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="#06a454"
                    variant="text"
                    @click="deleteItemConfirm"
                    >Eliminar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
    </div>
    <v-card-text>
      <v-data-table class="tabla"
        :headers="headers"
        :items="usuarios"
        :sort-by="[{ key: 'ApMatUsuario', order: 'asc'}]"
      >
      <template v-slot:top>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="me-2" size="small" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon size="small" @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Reset </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-app>
</template>

<script>
import backedosic from '@/components/api/apiosic.js'

export default {
  name: 'UsuariosComponent',
  data: () => ({
    contraseña: null,
    show1: false,
    show2: true,
    tipo: [],
    rules: {
      required: value => !!value || 'Campo requerido.',
      password: value => value.length >= 8 || 'Contraseña debe tener al menos 8 caracteres',
      userStartsWithAt: value => {
        return value.startsWith('@') || 'El usuario debe comenzar con @'
      }
    },
    showErrorDialog: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        title: 'Nombre',
        align: 'start',
        sortable: false,
        key: 'nomUsuario',
        background: 'red-lighten-5'
      },
      { title: 'Apellido Paterno', key: 'apPatUsuario', class: 'my-header-style' },
      { title: 'Apellido Materno', key: 'apMatUsuario' },
      { title: 'Usuario', key: 'usuario' },
      { title: 'Tipo Usuario', key: 'idTipoUsuario' },
      { title: ' ', key: 'actions', sortable: false, width: '100px' }
    ],
    usuarios: [],
    editedIndex: -1,
    editedItem: {
      idUsuario: '',
      nomUsuario: '',
      apPatUsuario: '',
      apMatUsuario: '',
      idTipoUsuario: '',
      usuario: '',
      contraseña: '',
      foto: ''
    },
    defaultItem: {
      idUsuario: '',
      nomUsuario: '',
      apPatUsuario: '',
      apMatUsuario: '',
      idTipoUsuario: '',
      usuario: '',
      contraseña: '',
      foto: ''
    },
    exists: ''
  }),

  computed: {
    formTitle () {
      if (this.editedIndex === -1) {
        return 'Nuevo Usuario'
      } else {
        return 'Editar Usuario'
      }
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },

  created () {
    this.initialize()
    this.getTipo()
  },

  methods: {
    initialize () {
      backedosic.get('/usuario').then(r => {
        this.usuarios = r.data
        this.getTipoUsuario()
      })
    },

    getTipoUsuario () {
      backedosic.get('/tipousuario').then(r => {
        const TipoUsuario = r.data
        this.usuarios.forEach(usuarios => {
          const tipo = TipoUsuario.find(t => t.idTipoUsuario === usuarios.idTipoUsuario)
          if (tipo) {
            usuarios.idTipoUsuario = tipo.nomTipoUsuario
          }
        })
      })
    },
    getIdTipo () {
      backedosic.get('/tipousuario').then(r => {
        const TipoUsuario = r.data
        const UsuarioActual = this.usuarios.find(usuarios => usuarios.idTipoUsuario === this.editedItem.idTipoUsuario) // Filtra el ordenado relacionado
        if (UsuarioActual) {
          const tipo = TipoUsuario.find(t => t.nomTipoUsuario === UsuarioActual.idTipoUsuario)
          if (tipo) {
            this.editedItem.idTipoUsuario = tipo.idTipoUsuario
          }
        }
      })
    },
    getTipo () {
      backedosic.get('/tipousuario')
        .then(r => {
          r.data.forEach(e => {
            this.tipo.push({ idTipoUsuario: e.idTipoUsuario, nomTipoUsuario: e.nomTipoUsuario })
          })
        })
    },

    editItem (item) {
      this.editedIndex = this.usuarios.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.getIdTipo()
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.usuarios.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.usuarios.splice(this.editedIndex, 1)

      backedosic.put(`/usuario/eliminar/${this.editedItem.idUsuario}`).then(r => {
        console.log('Usuario Eliminado correctamente')
        this.usuarios = r.data.usuarios
        this.initialize()
        this.close()
      })
        .catch(error => {
          console.log('Error al actualizar el dispositivo:', error)
        })

      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (!this.exists && this.editedItem.nomUsuario &&
      this.editedItem.usuario && this.editedItem.idTipoUsuario && this.editedItem.usuario.startsWith('@')
      ) {
        if (this.editedIndex > -1) {
          backedosic.put(`/usuario/${this.editedItem.idUsuario}`, this.editedItem).then(r => {
            console.log('Usuario actualizado correctamente')
            this.usuarios = r.data.usuarios
            this.initialize()
            this.close()
          }).catch(error => {
            console.log('Error al actualizar el Usuario:', error)
          })
        } else {
          backedosic.post('/usuario', this.editedItem).then(r => {
            console.log('Usuario creado correctamente')
            this.usuarios = r.data.usuarios
            this.initialize()
            this.close()
          }).catch(error => {
            console.log('Error al crear el Usuario:', error)
          })
        }
        this.close()
      } else {
        this.showErrorDialog = true
      }
    },
    userExists (value) {
      // Verifica si el usuario ya existe
      this.exists = this.usuarios.some(usuario => usuario.usuario === value && usuario.idUsuario !== this.editedItem.idUsuario)
      return !this.exists || 'El usuario ya existe'
    }
  }
}

</script>

<style>
.bottonusuario{
  text-align: right;
  padding: 0 50px;
}
</style>
