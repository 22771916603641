<template>
  <PanelVendedor/>
</template>

<script>
import { defineComponent } from 'vue'

// Components
import PanelVendedor from '../components/PanelVendedor.vue'

export default defineComponent({
  name: 'PanelUsuarioView',

  components: {
    PanelVendedor
  }
})
</script>
