import backedosic from '@/components/api/apiosic'
import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null
  },
  getters: {
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    }
  },
  actions: {
    async fetchUser ({ commit }) {
      return backedosic.get('/user')
        .then(response => {
          commit('setUser', response.data)
        })
    }
  },
  modules: {
  }
})
