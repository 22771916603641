<template>
  <Especificaciones></Especificaciones>
</template>

<script>
import { defineComponent } from 'vue'

import Especificaciones from '../components/Especificaciones.vue'

export default defineComponent({
  name: 'ClientesView',

  components: {
    Especificaciones
  }
})
</script>
