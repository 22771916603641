<template>
  <Perfil></Perfil>
</template>

<script>
import { defineComponent } from 'vue'

import Perfil from '../components/Perfil.vue'

export default defineComponent({
  name: 'PerfilView',

  components: {
    Perfil
  }
})
</script>
