import axios from 'axios'

const backedosic = axios.create({
  baseURL: 'https://sisgic.com.mx/api/api',
  withCredentials: true
})

// Si hay un token, lo agrega a los encabezados de Axios
backedosic.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
export const initCsrf = async () => {
  await backedosic.get('/sanctum/csrf-cookie') // Solicitud para obtener la cookie CSRF
}
export default backedosic
