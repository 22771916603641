<template>
  <v-app>
    <br />
    <v-card-title class="text-h4 font-weight-medium" style="margin-left: 20px">
      Clientes
    </v-card-title>
    <!-- Empresas -->
    <div class="bottonusuario">
      <!-- Dialogo para crear/editar clientes -->
      <v-dialog v-model="dialogCliente" max-width="600px" class="pa-4 text-center" persistent>
        <template v-slot:activator="{ props: activatorProps }">
          <v-btn size="large" color="#06a454" dark v-bind="activatorProps">
            Nueva Empresa/Cliente
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitleCliente }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-text-field v-model="editedItemCliente.nomCliente" label="Nombre" :rules="[rules.required]"></v-text-field>
              <v-row>
                <v-col>
                  <v-text-field v-model="editedItemCliente.telCliente" label="Teléfono" :rules="[rules.onlyTenNumbers]"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="editedItemCliente.dirCliente" label="Dirección"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="gray-darken-1" variant="text" @click="closeCliente">
              Cancelar
            </v-btn>
            <v-btn color="#06a454" variant="text" @click="saveCliente">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialogo para eliminar clientes -->
      <v-dialog v-model="dialogDeleteCliente" max-width="500px">
        <v-card>
          <v-card-title class="text-center">¿Seguro quieres eliminar este cliente?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="gray-darken-1" variant="text" @click="closeDeleteCliente">Cancelar</v-btn>
            <v-btn color="#06a454" variant="text" @click="deleteItemConfirmCliente">Eliminar</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-card-text>
      <v-data-table class="tabla" :headers="headersCliente" :items="clientes" :sort-by="[{ key: 'nomCliente', order: 'asc'}]">
        <template v-slot:top></template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="me-2" size="small" @click="editItemCliente(item)">mdi-pencil</v-icon>
          <v-icon size="small" @click="deleteItemCliente(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initializeClientes">Reset</v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <!-- ordenados -->
    <v-card-title class="text-h4 font-weight-medium" style="margin-left: 20px">
      Ordenados
    </v-card-title>
    <div class="bottonusuario">
      <!-- Dialogo para crear/editar Ordenado -->
      <v-dialog v-model="dialogOrdenado" max-width="600px" class="pa-4 text-center" persistent>
        <template v-slot:activator="{ props: activatorProps }">
          <v-btn size="large" color="#06a454" dark v-bind="activatorProps">
            Nuevo Ordenado
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitleOrdenado }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-text-field v-model="editedItemOrdenado.nomOrdenado" label="Nombre" :rules="[rules.required]"></v-text-field>
              <v-row>
                <v-col>
                  <v-text-field v-model="editedItemOrdenado.telOrdenado" label="Teléfono" :rules="[rules.onlyTenNumbers]"></v-text-field>
                </v-col>
                <v-col>
                  <v-autocomplete v-model="editedItemOrdenado.idCliente" :items="clienteSelect" item-title="nomCliente" item-value="idCliente"
                  label="Seleccione Cliente/Empresa" :rules="[rules.required]">
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="gray-darken-1" variant="text" @click="closeOrdenado">
              Cancelar
            </v-btn>
            <v-btn color="#06a454" variant="text" @click="saveOrdenado">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showErrorDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="text-center">
          <v-icon color="red" large class="mr-2">mdi-alert-circle</v-icon>
          Atención</v-card-title>
          <v-card-text class="text-center">
            Por favor, Complete todos los campos obligatorios antes de continuar.
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#06a454" text @click="showErrorDialog = false">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <!-- Dialogo para eliminar ordenados -->
      <v-dialog v-model="dialogDeleteOrdenado" max-width="500px" persistent>
        <v-card>
          <v-card-title class="text-center">¿Seguro quieres eliminar este Ordenado?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="gray-darken-1" variant="text" @click="closeDeleteOrdenado">Cancelar</v-btn>
            <v-btn color="#06a454" variant="text" @click="deleteItemConfirmOrdenado">Eliminar</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-card-text>
      <v-data-table class="tabla" :headers="headersOrdenado" :items="ordenados" :sort-by="[{ key: 'nomOrdenado', order: 'asc'}]">
        <template v-slot:top></template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="me-2" size="small" @click="editItemOrdenado(item)">mdi-pencil</v-icon>
          <v-icon size="small" @click="deleteItemOrdenado(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initializeordenados">Reset</v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-app>
</template>

<script>
import backedosic from '@/components/api/apiosic.js'

export default {
  name: 'ClientesComponent',
  data: () => ({
    // Clientes
    dialogCliente: false,
    dialogDeleteCliente: false,
    headersCliente: [
      { title: 'Empresa/Cliente', align: 'start', sortable: false, key: 'nomCliente', class: 'my-header-style' },
      { title: 'Teléfono', key: 'telCliente' },
      { title: 'Dirección', key: 'dirCliente' },
      { title: ' ', key: 'actions', sortable: false, width: '100px' }
    ],
    clientes: [],
    editedIndexCliente: -1,
    editedItemCliente: { nomCliente: '', telCliente: '', dirCliente: '' },
    defaultItemCliente: { nomCliente: '', telCliente: '', dirCliente: '' },
    // ordenados
    dialogOrdenado: false,
    dialogDeleteOrdenado: false,
    headersOrdenado: [
      { title: 'Ordenado', align: 'start', sortable: false, key: 'nomOrdenado', class: 'my-header-style' },
      { title: 'Teléfono', key: 'telOrdenado' },
      { title: 'Cliente/Empresa', key: 'idCliente' },
      { title: ' ', key: 'actions', sortable: false, width: '100px' }
    ],
    ordenados: [],
    clienteSelect: [],
    editedIndexOrdenado: -1,
    editedItemOrdenado: { nomOrdenado: '', telOrdenado: '', idCliente: null },
    defaultItemOrdenado: { nomOrdenado: '', telOrdenado: '', idCliente: null },
    rules: {
      required: value => !!value || 'Campo requerido.',
      onlyTenNumbers: value => {
        if (value === null || value === undefined || value === '') {
          return true
        }
        const regex = /^[0-9]{10}$/
        return regex.test(value) || 'Debe tener 10 números'
      }
    },
    showErrorDialog: false
  }),

  computed: {
    formTitleCliente () {
      return this.editedIndexCliente === -1 ? 'Nuevo Cliente' : 'Editar Cliente'
    },
    formTitleOrdenado () {
      return this.editedIndexOrdenado === -1 ? 'Nuevo Ordenado' : 'Editar Ordenado'
    },
    telefonoEnmascarado () {
      const telefono = this.editedItemCliente.telCliente
      if (telefono) {
        return telefono.replace(/^(\d{3})(\d{3})(\d{4})$/)
      }
      return ''
    }
  },

  watch: {
    dialogCliente (val) {
      val || this.closeCliente()
    },
    dialogDeleteCliente (val) {
      val || this.closeDeleteCliente()
    },
    dialogOrdenado (val) {
      val || this.closeOrdenado()
    },
    dialogDeleteOrdenado (val) {
      val || this.closeDeleteOrdenado()
    }
  },

  created () {
    this.initializeClientes()
    this.initializeordenados()
    this.getCliente()
  },

  methods: {
    // Métodos para clientes
    initializeClientes () {
      backedosic.get('/cliente').then(r => {
        this.clientes = r.data
      })
    },
    editItemCliente (item) {
      this.editedIndexCliente = this.clientes.indexOf(item)
      this.editedItemCliente = Object.assign({}, item)
      this.dialogCliente = true
    },
    deleteItemCliente (item) {
      this.editedIndexCliente = this.clientes.indexOf(item)
      this.editedItemCliente = Object.assign({}, item)
      this.dialogDeleteCliente = true
    },
    deleteItemConfirmCliente () {
      this.clientes.splice(this.editedIndexCliente, 1)
      backedosic.put(`/cliente/eliminar/${this.editedItemCliente.idCliente}`).then(r => {
        console.log('Cliente Eliminado correctamente')
        this.clientes = r.data.clientes
        this.initializeClientes()
        this.closeCliente()
      }).catch(error => {
        console.log('Error al actualizar el cliente:', error)
      })
      this.closeDeleteCliente()
    },
    closeCliente () {
      this.dialogCliente = false
      this.$nextTick(() => {
        this.editedItemCliente = Object.assign({}, this.defaultItemCliente)
        this.editedIndexCliente = -1
      })
    },
    closeDeleteCliente () {
      this.dialogDeleteCliente = false
      this.$nextTick(() => {
        this.editedItemCliente = Object.assign({}, this.defaultItemCliente)
        this.editedIndexCliente = -1
      })
    },
    saveCliente () {
      if (this.editedItemCliente.nomCliente && (!this.editedItemCliente.telCliente ||
      this.editedItemCliente.telCliente?.length === 10)) {
        if (this.editedIndexCliente > -1) {
          backedosic.put(`/cliente/${this.editedItemCliente.idCliente}`, this.editedItemCliente).then(r => {
            console.log('Cliente actualizado correctamente')
            this.clientes = r.data.clientes
            this.initializeClientes()
            this.closeCliente()
          }).catch(error => {
            console.log('Error al actualizar el Cliente:', error)
          })
        } else {
          backedosic.post('/cliente', this.editedItemCliente).then(r => {
            console.log('Cliente creado correctamente')
            this.clientes = r.data.clientes
            this.initializeClientes()
            this.closeCliente()
          }).catch(error => {
            console.log('Error al crear el Cliente:', error)
          })
        }
        this.closeCliente()
      } else {
        this.showErrorDialog = true
      }
    },

    initializeordenados () {
      backedosic.get('/ordenado').then(r => {
        this.ordenados = r.data
        this.getIdCliente()
      })
    },
    getIdCliente () {
      backedosic.get('/cliente').then(r => {
        const Cliente = r.data
        this.ordenados.forEach(ordenados => {
          const tipo = Cliente.find(t => t.idCliente === ordenados.idCliente)
          if (tipo) {
            ordenados.idCliente = tipo.nomCliente
          }
        })
      })
    },
    getIdClienteXnom () {
      backedosic.get('/cliente').then(r => {
        const Cliente = r.data
        const ordenadoActual = this.ordenados.find(o => o.idCliente === this.editedItemOrdenado.idCliente) // Filtra el ordenado relacionado
        if (ordenadoActual) {
          const tipo = Cliente.find(t => t.nomCliente === ordenadoActual.idCliente)
          if (tipo) {
            this.editedItemOrdenado.idCliente = tipo.idCliente
          }
        }
      })
    },
    getCliente () {
      backedosic.get('/cliente')
        .then(r => {
          r.data.forEach(e => {
            this.clienteSelect.push({ idCliente: e.idCliente, nomCliente: e.nomCliente })
          })
        })
    },
    editItemOrdenado (item) {
      this.editedIndexOrdenado = this.ordenados.indexOf(item)
      this.editedItemOrdenado = Object.assign({}, item)
      this.getIdClienteXnom()
      console.log('ID Cliente original:', item)
      this.editedItemOrdenado.idCliente = item.idCliente
      this.dialogOrdenado = true
    },
    deleteItemOrdenado (item) {
      this.editedIndexOrdenado = this.ordenados.indexOf(item)
      this.editedItemOrdenado = Object.assign({}, item)
      this.dialogDeleteOrdenado = true
    },
    deleteItemConfirmOrdenado () {
      this.ordenados.splice(this.editedIndexOrdenado, 1)
      backedosic.put(`/ordenado/eliminar/${this.editedItemOrdenado.idOrdenado}`).then(r => {
        console.log('Ordenado Eliminado correctamente')
        this.ordenados = r.data.ordenados
        this.initializeordenados()
        this.closeOrdenado()
      }).catch(error => {
        console.log('Error al actualizar el Ordenado:', error)
      })
      this.closeDeleteOrdenado()
    },
    closeOrdenado () {
      this.dialogOrdenado = false
      this.$nextTick(() => {
        this.editedItemOrdenado = Object.assign({}, this.defaultItemOrdenado)
        this.editedIndexOrdenado = -1
      })
    },
    closeDeleteOrdenado () {
      this.dialogDeleteOrdenado = false
      this.$nextTick(() => {
        this.editedItemOrdenado = Object.assign({}, this.defaultItemOrdenado)
        this.editedIndexOrdenado = -1
      })
    },
    saveOrdenado () {
      console.log('Datos antes de enviar:', this.editedItemOrdenado)
      if (this.editedItemOrdenado.nomOrdenado && (!this.editedItemOrdenado.telOrdenado ||
      this.editedItemOrdenado.telOrdenado?.length === 10) && this.editedItemOrdenado.idCliente) {
        if (this.editedIndexOrdenado > -1) {
          backedosic.put(`/ordenado/${this.editedItemOrdenado.idOrdenado}`, this.editedItemOrdenado).then(r => {
            console.log('Ordenado actualizado correctamente')
            this.ordenados = r.data.ordenados
            this.initializeordenados()
            this.closeOrdenado()
          }).catch(error => {
            console.log('Error al actualizar el Ordenado:', error)
          })
        } else {
          backedosic.post('/ordenado', this.editedItemOrdenado).then(r => {
            console.log('Ordenado creado correctamente')
            this.ordenados = r.data.ordenados
            this.initializeordenados()
            this.closeOrdenado()
          }).catch(error => {
            console.log('Error al crear el Ordenado:', error)
          })
        }
        this.closeOrdenado()
      } else {
        this.showErrorDialog = true
      }
    }
  }
}
</script>

<style scoped>
.my-header-style {
  background-color: red;
}
.bottonusuario{
  text-align: right;
  padding: 0 50px;
}
</style>
