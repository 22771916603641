<template>

  <Ordenes></Ordenes>

</template>

<script>
import { defineComponent } from 'vue'

import Ordenes from '../components/Ordenes.vue'

export default defineComponent({
  name: 'OrdenesView',

  components: {
    Ordenes
  }
})
</script>
