<template>
  <v-app>
    <br />
    <v-card-title class="text-h4 font-weight-medium" style="margin-left: 20px">
      Especificaciones
    </v-card-title>
    <v-dialog v-model="dialog" max-width="600px" class="pa-4 text-center" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field v-if="currentSection === 'forma'" v-model="editedItem.nombreForma" label="Forma" :rules="[rules.required]"></v-text-field>
            <v-text-field v-if="currentSection === 'impresion'" v-model="editedItem.TipoImpresion" label="Impresion" :rules="[rules.required]"></v-text-field>
            <v-text-field v-if="currentSection === 'papel'" v-model="editedItem.nombrePapel" label="Papel" :rules="[rules.required]"></v-text-field>
            <v-text-field v-if="currentSection === 'tamano'" v-model="editedItem.nombreTamano" label="Tamaño" :rules="[rules.required]"></v-text-field>
            <v-text-field v-if="currentSection === 'tipoforma'" v-model="editedItem.nombreTipoForma" label="Tipo de Forma" :rules="[rules.required]"></v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray-darken-1" variant="text" @click="close">
            Cancelar
          </v-btn>
          <v-btn color="#06a454" variant="text" @click="save">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div>
      <v-row>
        <v-col cols="12" md="4">
          <v-card elevation="4" max-width="460" class="mx-auto">
            <v-list>
              <v-list-item>
                <v-list-item-title class="title" style="display: flex; justify-content: space-between; align-items: center">
                  Forma
                  <v-btn color="#06a454" dark @click="openDialog('forma')">
                    Nueva Forma
                  </v-btn>
                </v-list-item-title>
                <v-data-table items-per-page="8" class="elevation-1" :headers="forma" :items="formas" hide-default-header>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon size="small" @click="editItem(item, 'forma')">
                      mdi-pencil
                    </v-icon>
                    <v-icon size="small" @click="deleteItem(item, 'forma')">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card elevation="4" max-width="460" class="mx-auto">
            <v-list>
              <v-list-item>
                <v-list-item-title class="title" style="display: flex; justify-content: space-between; align-items: center">
                  Papel
                  <v-btn color="#06a454" dark @click="openDialog('papel')">
                    Nuevo Papel
                  </v-btn>
                </v-list-item-title>
                <v-data-table items-per-page="8" class="elevation-1" :headers="papel" :items="papels" hide-default-header>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon size="small" @click="editItem(item, 'papel')">
                      mdi-pencil
                    </v-icon>
                    <v-icon size="small" @click="deleteItem(item, 'papel')">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card elevation="4" max-width="460" class="mx-auto">
            <v-list>
              <v-list-item>
                <v-list-item-title class="title" style="display: flex; justify-content: space-between; align-items: center">
                  Impresión
                  <v-btn color="#06a454" dark @click="openDialog('impresion')">
                    Nueva Impresión
                  </v-btn>
                </v-list-item-title>
                <v-data-table items-per-page="8" class="elevation-1" :headers="impresion" :items="impresions" hide-default-header>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon size="small" @click="editItem(item, 'impresion')">
                      mdi-pencil
                    </v-icon>
                    <v-icon size="small" @click="deleteItem(item, 'impresion')">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <br />
    <br />
    <div>
      <v-row>
        <v-col cols="12" md="4">
          <v-card elevation="4" max-width="460" class="mx-auto py-8 mb-8">
            <v-list>
              <v-list-item>
                <v-list-item-title class="title" style="display: flex; justify-content: space-between; align-items: center">
                  Tamaño
                  <v-btn color="#06a454" dark @click="openDialog('tamano')">
                    Nuevo Tamaño
                  </v-btn>
                </v-list-item-title>
                <v-data-table items-per-page="8" class="elevation-1" :headers="tamano" :items="tamanos" hide-default-header>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon size="small" @click="editItem(item, 'tamano')">
                      mdi-pencil
                    </v-icon>
                    <v-icon size="small" @click="deleteItem(item, 'tamano')">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card elevation="4" max-width="460" max-height="700px" class="mx-auto py-8 mb-8">
            <v-list>
              <v-list-item>
                <v-list-item-title class="title" style="display: flex; justify-content: space-between; align-items: center">
                  Tipo de Forma
                  <v-btn color="#06a454" dark @click="openDialog('tipoforma')">
                    Nuevo Tipo de Forma
                  </v-btn>
                </v-list-item-title>
                <v-data-table items-per-page="8" class="elevation-1" :headers="tipoforma" :items="tipoformas" hide-default-header>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon size="small" @click="editItem(item, 'tipoforma')">
                      mdi-pencil
                    </v-icon>
                    <v-icon size="small" @click="deleteItem(item, 'tipoforma')">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="showErrorDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="text-center">
          <v-icon color="red" large class="mr-2">mdi-alert-circle</v-icon>
          Atención</v-card-title>
          <v-card-text class="text-center">
            Por favor, Ingrese nombre de {{ nombre }} antes de continuar.
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#06a454" text @click="showErrorDialog = false">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
              <v-card>
                <v-card-title class="text-center"
                  >¿Seguro quieres eliminar {{ textConfirmation }}?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="gray-darken-1"
                    variant="text"
                    @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="#06a454"
                    variant="text"
                    @click="deleteItemConfirm"
                    >Eliminar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
    </v-dialog>
  </v-app>
  </template>

<script>
import backedosic from '@/components/api/apiosic.js'

export default {
  name: 'EspecificacionesComponent',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    currentSection: '',
    formas: [],
    papels: [],
    impresions: [],
    tamanos: [],
    tipoformas: [],
    rules: {
      required: value => !!value || 'Campo requerido.'
    },
    showErrorDialog: false,
    editedIndex: -1,
    editedItem: {
      idForma: '',
      nombreForma: '',
      nombrePapel: '',
      TipoImpresion: '',
      nombreTamano: '',
      nombreTipoForma: ''
    },
    defaultItem: {
      idForma: '',
      nombreForma: '',
      nombrePapel: '',
      TipoImpresion: '',
      nombreTamano: '',
      nombreTipoForma: ''
    },
    tamano: [
      { key: 'nombreTamano', sortable: false, width: '350px' },
      { text: 'Actions', value: 'actions', sortable: false, width: '100px' }
    ],
    forma: [
      { key: 'nombreForma', soportable: false, width: '350px' },
      { text: 'Actions', value: 'actions', sortable: false, width: '100px' }
    ],
    papel: [
      { key: 'nombrePapel', soportable: false, width: '350px' },
      { text: 'Actions', value: 'actions', sortable: false, width: '100px' }
    ],
    impresion: [
      { key: 'TipoImpresion', soportable: false, width: '350px' },
      { text: 'Actions', value: 'actions', sortable: false, width: '100px' }
    ],
    tipoforma: [
      { key: 'nombreTipoForma', soportable: false, width: '350px' },
      { text: 'Actions', value: 'actions', sortable: false, width: '100px' }
    ]
  }),
  computed: {
    formTitle () {
      const idField = `${this.currentSection.charAt(0).toUpperCase() + this.currentSection.slice(1)}`
      if (this.currentSection === 'forma' || this.currentSection === 'impresion') {
        return this.editedIndex === -1 ? `Nueva ${idField}` : `Editar ${idField}`
      } else if (this.currentSection === 'papel' || this.currentSection === 'tamano') {
        return this.editedIndex === -1 ? `Nuevo ${idField}` : `Editar ${idField}`
      } else {
        return this.editedIndex === -1 ? 'Nuevo Tipo de Forma' : 'Editar Tipo de Forma'
      }
    },
    textConfirmation () {
      const idField = `${this.currentSection.charAt(0).toUpperCase() + this.currentSection.slice(1)}`
      if (this.currentSection === 'forma' || this.currentSection === 'impresion' || this.currentSection === 'tipoforma') {
        return `esta ${idField}`
      } else {
        return `este ${idField}`
      }
    },
    nombre () {
      const idField = `${this.currentSection.charAt(0).toUpperCase() + this.currentSection.slice(1)}`
      return idField
    }
  },
  created () {
    this.initialize()
  },

  methods: {
    initialize () {
      backedosic.get('/tamano').then(r => {
        this.tamanos = r.data
      })
      backedosic.get('/forma').then(r => {
        this.formas = r.data
      })
      backedosic.get('/papel').then(r => {
        this.papels = r.data
      })
      backedosic.get('/impresion').then(r => {
        this.impresions = r.data
      })
      backedosic.get('/tipoforma').then(r => {
        this.tipoformas = r.data
      })
    },
    openDialog (section) {
      this.dialog = true
      this.currentSection = section
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    },
    editItem (item, section) {
      this.dialog = true
      this.currentSection = section
      this.editedItem = Object.assign({}, item)
      this.editedIndex = this[section + 's'].indexOf(item)
    },
    deleteItem (item, section) {
      this.currentSection = section
      this.editedItem = Object.assign({}, item)
      this.editedIndex = this[section + 's'].indexOf(item)
      this.dialogDelete = true
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteItemConfirm  () {
      const idField = `id${this.currentSection.charAt(0).toUpperCase() + this.currentSection.slice(1)}`
      console.log('idField' + this.currentSection)
      console.log('index: ' + idField)
      backedosic.put(`/${this.currentSection}/eliminar/${this.editedItem[idField]}`).then(() => {
        this.initialize()
        this[this.currentSection + 's'].splice(this.editedIndex, 1)
        this.close()
        console.log(`${this.currentSection} eliminado correctamente`)
      }).catch(error => {
        console.log(`Error al eliminar el ${this.currentSection}:`, error)
      })
      this.closeDelete()
      this.dialogConfirmeDelete = true
    },
    close () {
      this.dialog = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    },
    save () {
      if (this.editedItem.nombreForma || this.editedItem.TipoImpresion || this.editedItem.nombrePapel ||
      this.editedItem.nombreTamano || this.editedItem.nombreTipoForma
      ) {
        const idField = `id${this.currentSection.charAt(0).toUpperCase() + this.currentSection.slice(1)}`
        if (this.editedIndex > -1) {
          if (this.currentSection === 'tipoforma') {
            backedosic.put(`/${this.currentSection}/${this.editedItem.idTipoForma}`, this.editedItem).then(r => {
              console.log(`${this.currentSection} actualizado correctamente`)
              this[this.currentSection + 's'].push(r.data)
              this.initialize()
            }).catch(error => {
              console.log(`Error al actualizar el ${this.currentSection}:`, error)
            })
          } else {
            backedosic.put(`/${this.currentSection}/${this.editedItem[idField]}`, this.editedItem).then(r => {
              console.log(`${this.currentSection} actualizado correctamente`)
              this[this.currentSection + 's'].push(r.data)
              console.log(idField)
              this.initialize()
            }).catch(error => {
              console.log(`Error al actualizar el ${this.currentSection}:`, error)
            })
          }
        } else {
          backedosic.post(`/${this.currentSection}`, this.editedItem).then(r => {
            console.log(`${this.currentSection} creado correctamente`)
            this[this.currentSection + 's'].push(r.data)
            this.initialize()
          }).catch(error => {
            console.log(`Error al crear el ${this.currentSection}:`, error)
          })
        }
        this.close()
      } else {
        console.log('no entro')
        this.showErrorDialog = true
      }
    }
  }
}
</script>
<style>
  .title {
    font-weight: bold;
    font-size: 24px; /* Ajusta el tamaño según tus necesidades */
  }
  .small-icon {
    font-size: 15px; /* Ajusta el tamaño según tus necesidades */
  }
  .v-data-table-header th {
    font-weight: bold;
    font-size: 24px; /* Ajusta el tamaño del encabezado según tus necesidades */
  }
</style>
