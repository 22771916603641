<template>
  <v-app>
    <br />
    <v-card-title class="text-h4 font-weight-medium" style="margin-left: 20px">
      Perfil
    </v-card-title>
    <v-main class="d-flex justify-center align-center">
      <v-row>
      <v-col cols="10" lg="4" class="mx-auto">
        <v-card class="mb-4">
          <div class="img-container">
            <v-img alt="CG3" src="https://cdn4.iconfinder.com/data/icons/music-ui-solid-24px/24/user_account_profile-2-256.png" />
          </div>
          <br />
            <v-card-text>
              <v-text-field
                v-model="usuarios.nomUsuario"
                class="mb-2"
                label="Nombre"
                prepend-inner-icon="mdi-account"
                readonly
              ></v-text-field>
              <v-text-field
                v-model="usuarios.tipoUsuario"
                class="mb-2"
                label="Tipo Usuario"
                prepend-inner-icon="mdi-account-box"
                readonly
              ></v-text-field>
              <v-text-field
                v-model="usuarios.usuario"
                :rules="[rules.required, rules.userStartsWithAt, userExists]"
                class="mb-2"
                label="Usuario"
                prepend-inner-icon="mdi-account-circle"
                :readonly="!isEditing"
              ></v-text-field>
              <v-text-field
               v-show="isEditingPassword"
                v-model="usuarios.contraseña"
                :rules="[rules.required, rules.password]"
                label="Contraseña"
                placeholder="Ingrese su contraseña"
                :type="showPassword ? 'text' : 'password'"
                hint="Ingrese 8 caracteres"
                name="input-10-1"
                counter
                @click:append-inner="showPassword = !showPassword"
                prepend-inner-icon="mdi-lock"
                :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :readonly="!isEditingPassword"
              ></v-text-field>
              <v-text-field
                v-show="isEditingPassword"
                v-model="confirmPassword"
                :rules="[rules.required, rules.password]"
                label="Confirmar nueva contraseña"
                placeholder="Ingrese su contraseña"
                :type="showConfirmPassword ? 'text' : 'password'"
                hint="Ingrese 8 caracteres"
                name="input-10-1"
                counter
                @click:append-inner="showConfirmPassword = !showConfirmPassword"
                prepend-inner-icon="mdi-lock"
                :append-inner-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :readonly="!isEditingPassword"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn v-if="!isEditing && !isEditingPassword" color="#06a454" variant="text" @click="enableEdit">
                Editar
                </v-btn>
                <v-btn v-if="!isEditing && !isEditingPassword" color="#06a454" variant="text" @click="PasswordEditDialog=true">
                Cambiar contraseña
                </v-btn>
                <v-btn v-if="isEditingPassword" color="#06a454" variant="text" @click="changePassword">
                  Aceptar
                </v-btn>
                <v-btn v-if="isEditing" color="#06a454" variant="text" @click="saveChanges">
                  Guardar
                </v-btn>
                <v-btn v-if="isEditing || isEditingPassword" color="gray-darken-1" variant="text" @click="cancelEdit">
                  Cancelar
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="showErrorDialog" max-width="500" persistent>
            <v-card>
              <v-card-title class="text-center">
                <v-icon color="red" large class="mr-2">mdi-alert-circle</v-icon>
                Atención</v-card-title>
                <v-card-text class="text-center">
                  Por favor, Complete los campos correctamente antes de continuar.
                </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#06a454" text @click="showErrorDialog = false">Aceptar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="PasswordEditDialog" max-width="500" persistent>
            <v-card>
              <v-card-title class="text-center">
                <v-icon color="red" class="mr-2">mdi-lock-alert</v-icon>
                Ingrese su contraseña para continuar</v-card-title>
                <v-card-text class="text-center">
                  <v-text-field
                  ref="passwordField"
                  v-model="password"
                  :rules="[rules.required, rules.password]"
                  label="Contraseña"
                  placeholder="Ingrese su contraseña"
                  :type="showPassword ? 'text' : 'password'"
                  hint="Ingrese 8 caracteres"
                  name="input-10-1"
                  counter
                  @click:append-inner="showPassword = !showPassword"
                  prepend-inner-icon="mdi-lock"
                  :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                ></v-text-field>
                </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#06a454" text @click="checkPassword">Aceptar</v-btn>
                <v-btn color="gray-darken-1" variant="text" @click="calcelPasswordEdit">
                Cancelar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Mostrar el mensaje de éxito o error -->
          <v-alert v-if="message" :type="messageType" dismissible>
            {{ message }}
          </v-alert>
      </v-col>
    </v-row>
    </v-main>
  </v-app>
</template>

<script>
import backedosic from '@/components/api/apiosic.js'

export default {
  name: 'PerfilComponent',
  data: () => ({
    usuarios: {},
    TipoUsuario: [],
    showPassword: false,
    showConfirmPassword: false,
    loading: false,
    isEditing: false,
    showErrorDialog: false,
    PasswordEditDialog: false,
    isEditingPassword: false,
    currentPassword: '',
    exists: '',
    usuariosAux: [],
    password: '',
    confirmPassword: '',
    message: '',
    messageType: '',
    rules: {
      required: value => !!value || 'Campo requerido.',
      password: value => value.length >= 8 || 'Contraseña debe tener al menos 8 caracteres',
      userStartsWithAt: value => {
        return value.startsWith('@') || 'El usuario debe comenzar con @'
      }
    }
  }),
  created () {
    this.initializeUsuarios()
    this.$store.dispatch('fetchUser')
      .then(() => {
        this.initialize()
      })
      .catch(error => {
        console.error('Error al obtener el usuario:', error)
      })
  },
  methods: {
    initialize () {
      if (this.$store.state.user && this.$store.state.user.idUsuario) {
        backedosic.get(`/usuario/${this.$store.state.user.idUsuario}`)
          .then(response => {
            this.usuarios = response.data.data
            this.usuarios.nomUsuario = response.data.data.nomUsuario + ' ' + response.data.data.apMatUsuario +
            ' ' + response.data.data.apPatUsuario
            this.getTipoUsuario()
          })
          .catch(error => {
            console.error('Error fetching data: ', error)
          })
      } else {
        console.error('Usuario no encontrado')
      }
    },
    getTipoUsuario () {
      backedosic.get('/tipousuario')
        .then(response => {
          const tipoUsuarios = response.data
          // Crear un mapa de tipo de usuario
          const tipoUsuarioMap = {}
          tipoUsuarios.forEach(tipo => {
            tipoUsuarioMap[tipo.idTipoUsuario] = tipo.nomTipoUsuario
          })

          // Asignar el nombre del tipo de usuario al usuario actual
          if (this.usuarios.idTipoUsuario) {
            this.usuarios.tipoUsuario = tipoUsuarioMap[this.usuarios.idTipoUsuario] || this.usuarios.idTipoUsuario // Usar el nombre o mantener el ID si no se encuentra
          }
        })
        .catch(error => {
          console.error('Error fetching tipo de usuario', error)
        })
    },
    enableEdit () {
      this.isEditing = true
    },
    saveChanges () {
      if (!this.exists && this.usuarios.usuario && this.usuarios.usuario.startsWith('@')) {
        if (this.isEditing === true) {
          backedosic.put(`/usuario@Usuario/${this.$store.state.user.idUsuario}`, { usuario: this.usuarios.usuario }).then(r => {
            console.log('Usuario actualizado correctamente')
            this.usuarios = r.data.data // Aquí asegúrate de que estás asignando correctamente los datos
            this.initialize()
            this.isEditing = false
          }).catch(error => {
            console.log('Error al actualizar el Usuario:', error)
          })
        }
        this.isEditing = false
      } else {
        this.showErrorDialog = true
      }
    },
    cancelEdit () {
      this.isEditing = false
      this.isEditingPassword = false
      this.initialize()
      this.clearPasswords()
    },
    calcelPasswordEdit () {
      this.PasswordEditDialog = false
      this.initialize()
      this.clearPasswords()
    },
    initializeUsuarios () {
      backedosic.get('/usuario').then(r => {
        this.usuariosAux = r.data
      })
    },
    userExists (value) {
      // Verifica si el usuario ya existe
      this.exists = this.usuariosAux.some(usuario => usuario.usuario === value && usuario.idUsuario !== this.$store.state.user.idUsuario)
      return !this.exists || 'El usuario ya existe'
    },
    checkPassword () {
    // Llama a tu API para obtener la contraseña actual del usuario
      if (this.password && this.password >= 8) {
        backedosic.post(`/checkPassword/${this.$store.state.user.idUsuario}`, { contraseña: this.password })
          .then(response => { // Asegúrate de que la estructura sea correcta
            this.PasswordEditDialog = false
            this.isEditingPassword = true
          })
          .catch(error => {
            console.error('Error al obtener la contraseña actual:', error)
            this.isEditingPassword = false
            this.showErrorDialog = true
          })
      } else {
        this.showErrorDialog = true
        this.clearPasswords()
      }
    },
    changePassword () {
      if (this.usuarios.contraseña && this.usuarios.contraseña.length >= 8) {
        if (this.isEditingPassword === true) {
          backedosic.put(`/updatePassword/${this.$store.state.user.idUsuario}`, {
            contraseña: this.usuarios.contraseña,
            contraseña_confirmation: this.confirmPassword
          }).then(r => {
            console.log('contraseña actualizada correctamente')
            this.isEditingPassword = false
            this.clearPasswords()
            this.message = r.data.message
            this.messageType = 'success'
          }).catch(error => {
            console.log('Error al actualizar la contraseña:', error)
            this.message = 'Error al cambiar la contraseña'
            this.messageType = 'error'
          })
        }
      } else {
        this.showErrorDialog = true
        this.clearPasswords()
      }
    },
    clearPasswords () {
      this.usuarios.contraseña = ''
      this.password = ''
      this.confirmPassword = ''
    }
  }
}
</script>

<style scoped>
.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
