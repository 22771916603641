<template>
  <v-app>
    <v-card class="mb-4">
      <v-layout>
        <v-app-bar color="#06a454"
        style="height: 70px;" prominent>
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>OSIC</v-toolbar-title>
        <v-icon large class="mr-2">mdi-account</v-icon>
        <span class="mr-4">Bienvenido, {{ nombreUsuario }}</span>
        <v-btn icon @click="navigate('home')">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-app-bar>
        <v-navigation-drawer app :width="300"
          v-model="drawer"
          color="#06a454"
          rail
          @click="rail = false"
          expand-on-hover
        >

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              prepend-icon="mdi-account"
              title="Perfil"
              value="perfil"
              @click="navigate('PerfilUsuario')"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-clipboard-text"
              title="Ordenes"
              value="ordenes"
              @click="navigate('OrdenesPanelUsuario')"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-home-city"
              title="Clientes"
              value="clientes"
              @click="navigate('ClientesUsuario')"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-view-dashboard"
              title="Especificaciones"
              value="especificaciones"
              @click="navigate('EspecificacionesUsuario')"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-logout"
              title="Salir"
              value="salir"
              @click="navigate('home')"
            ></v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-main style="height: auto">
          <router-view></router-view>
        </v-main>
      </v-layout>
    </v-card>
  </v-app>
</template>

<script>
import backedosic from '@/components/api/apiosic.js'

export default {
  name: 'PanelVendedorComponent',

  data () {
    return {
      drawer: true,
      rail: true,
      nombre: ''
    }
  },
  computed: {
    // Utiliza Vuex para obtener el nombre del usuario
    nombreUsuario () {
      return this.$store.state.user?.nombre || 'Usuario' // Asegúrate de que el nombre exista en el store
    }
  },
  methods: {
    async navigate (route) {
      if (route === 'home') {
        try {
          const token = this.getToken() // Función para obtener el token (ajusta según tu implementación)

          // Realizar la solicitud POST para logout
          const response = await backedosic.post('/logout', { token })

          // Manejar la respuesta del logout
          if (response.status === 200) {
            console.log(response.data.message) // Mensaje de éxito
            // Aquí puedes eliminar el token o cualquier otro dato del usuario
            // redirigir a la página de inicio
            this.$router.push({ name: route })
          }
        } catch (error) {
          console.error('Error al cerrar sesión:', error.response.data.message)
        }
      } else {
        // Para otras rutas
        this.$router.push({ name: route })
      }
    },
    getToken () {
    // Implementa esta función para obtener el token del almacenamiento local o de donde lo tengas guardado
      return localStorage.getItem('token') // Ejemplo para obtenerlo del localStorage
    }
  }
}
</script>

<style></style>
